<template>
  <div>
    <h1>New project</h1>

    <div class="w-full lg:w-1/2 my-4">
      <form @submit="submitForm" class="pt-2 p-6 bg-white rounded shadow-lg leading-loose">
        <div>
          <label class="block text-gray-600 py-3">
            Project Name:
          </label>
          <input class="w-full px-3 py-1 text-gray-700 bg-gray-200 rounded" type="text" v-model="project_name">
        </div>
        <div>
          <label class="block text-gray-600 py-3" for="name">
            Account Name:
          </label>
          <select v-model="account_id" class="w-full h-9 px-3 py-1 text-gray-700 bg-gray-200 rounded">
          <option v-for="account in accounts" :key="account.id" :value="account.id">{{ account.name }} ({{ account.username }})</option>
          </select>
        </div>
        <div class="mt-6">
          <button class="px-4 py-1 text-white font-light tracking-wider bg-gray-900 rounded" type="submit">Create</button>
        </div>
      </form>
    </div>

  </div>
</template>

<script>
import axios from 'axios'

export default {
  props: ['projectId'],

  data() {
    return {
      project_name: null,
      account_id: null,
      accounts: []
    }
  },
  created() {
    // Get accounts
    axios
      .get(`${process.env.VUE_APP_API_URL}/accounts?purpose=messages`)
      .then(response => {
        this.accounts = response.data;
      });
  },
  methods: {
    submitForm(evt) {
      evt.preventDefault();

      axios.post(`${process.env.VUE_APP_API_URL}/projects`, {
          name: this.project_name,
          account_id: this.account_id,
        })
        .then(response => {
          this.$router.push(`/projects/${response.data.id}/recipients`)
        })
        .catch(error => {
          console.log(error)
        });
    }
  }
}
</script>